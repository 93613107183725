<script>

import { downloadImage } from "../libs/utils.js"
import Carousel from '../components/Carousel.vue';
export default {

  data() {
    return {
      showImageInGallery: ''
    }
  },
  methods: {
    formatDatetime(datetime) {
      const date = new Date(datetime);
      return date.toLocaleString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
    notifyViewResizing() {
      this.$emit('image-loaded')
    },
    emitBackToTopEvent() {
      this.$emit('back-to-top');
    },
    async onDownloadImage(url, prompt) {
      try {
        console.log("Image download triggered.");
        downloadImage(url, prompt)
      } catch (error) {
        alert("Error while downloading image. Try later.")
        console.error(error)
      }
    },
  },
  components: {
    Carousel
  },
  props: {
    generatedImages: {
      type: Object,
      required: true,
    },
    showButton: {
      type: Boolean,
      required: true
    }
  }
}

</script>

<template>
  <ul role="list" class="divide-y divide-gray-100" v-for="generatedImg in generatedImages" :key="generatedImg.id">
    <li class="relative ">
      <div class="relative py-4">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300 dark:border-gray-700" />
        </div>
        <div class="relative flex justify-center">
          <span class="dark:bg-gray-900 px-2 text-sm text-gray-500 dark:text-gray-200">{{
            formatDatetime(generatedImg.created_at)
          }}</span>
        </div>
      </div>

      <div class="flex mb-2 items-center md:justify-normal justify-center w-full text-gray-300">

        <p class="text-left mr-6 truncate w-full ">{{ generatedImg.prompt }}...</p>
        <button class="p-1 bg-gray-700 hover:bg-gray-800 text-white flex rounded-md "
          @click="$emit('reuse-generation', generatedImg)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="h-4 w-4" fill="currentColor"
            className="w-5 h-5">
            <path fillRule="evenodd"
              d="M10 4.5c1.215 0 2.417.055 3.604.162a.68.68 0 01.615.597c.124 1.038.208 2.088.25 3.15l-1.689-1.69a.75.75 0 00-1.06 1.061l2.999 3a.75.75 0 001.06 0l3.001-3a.75.75 0 10-1.06-1.06l-1.748 1.747a41.31 41.31 0 00-.264-3.386 2.18 2.18 0 00-1.97-1.913 41.512 41.512 0 00-7.477 0 2.18 2.18 0 00-1.969 1.913 41.16 41.16 0 00-.16 1.61.75.75 0 101.495.12c.041-.52.093-1.038.154-1.552a.68.68 0 01.615-.597A40.012 40.012 0 0110 4.5zM5.281 9.22a.75.75 0 00-1.06 0l-3.001 3a.75.75 0 101.06 1.06l1.748-1.747c.042 1.141.13 2.27.264 3.386a2.18 2.18 0 001.97 1.913 41.533 41.533 0 007.477 0 2.18 2.18 0 001.969-1.913c.064-.534.117-1.071.16-1.61a.75.75 0 10-1.495-.12c-.041.52-.093 1.037-.154 1.552a.68.68 0 01-.615.597 40.013 40.013 0 01-7.208 0 .68.68 0 01-.615-.597 39.785 39.785 0 01-.25-3.15l1.689 1.69a.75.75 0 001.06-1.061l-2.999-3z"
              clipRule="evenodd" />
          </svg>
        </button>
      </div>

      <div class="relative">
        <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-8">
          <!-- Ajustement de l'espacement entre les images -->

          <div class="relative flex flex-col space-y-4 group  items-center  overflow-hidden">
            <div class="relative overflow-hidden z-10">
              <img @load="notifyViewResizing()"
                class="rounded-lg h-auto max-w-[612px] w-full relative transition-transform duration-300 ease-in-out transform hover:scale-110"
                :src="generatedImg.images_urls[0]" alt="image description"
                @click="showImageInGallery = generatedImg.images_urls[0]">
              <div
                class="absolute bottom-0 left-0 right-0 h-1/3 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-80 transition-opacity">
              </div> <!-- Dégradé en bas de l'image -->
              <!-- <button
                class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 bg-gray-700 hover:bg-gray-800 text-white flex items-center justify-center rounded-full opacity-0 group-hover:opacity-70 transition-opacity">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor"
                  className="w-5 h-5">
                  <path fillRule="evenodd"
                    d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                    clipRule="evenodd" />
                </svg>

              </button> -->
              <button
                class="absolute bottom-4 right-4 p-2 bg-gray-700 hover:bg-gray-800 text-white flex items-center rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                @click="onDownloadImage(generatedImg.images_urls[0], generatedImg.prompt)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor"
                  className="w-5 h-5">
                  <path
                    d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                  <path
                    d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                </svg>
              </button>
            </div>
          </div>


        </div>
      </div>

    </li>
  </ul>

  <button type="button" v-if="showButton" @click="emitBackToTopEvent"
    class="!fixed bottom-5 right-5 rounded-full bg-red-600 p-3 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg"
    id="btn-back-to-top">
    <svg aria-hidden="true" focusable="false" data-prefix="fas" class="h-4 w-4" role="img"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path fill="currentColor"
        d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z">
      </path>
    </svg>
  </button>

  <!-- Modal Viewer Carousel -->
  <carousel :open-with-url="showImageInGallery" @close-mod="showImageInGallery = ''" :generated-images="generatedImages">
  </carousel>
</template>


