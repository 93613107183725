<template>
  <router-view></router-view>
</template>

<script setup>
import { onMounted } from 'vue';
import apiClient from './libs/api.js';
onMounted(async () => {
  apiClient.populateCSRFToken();
});
</script>

