<script setup>
import Cookies from 'js-cookie';
const csrftoken = Cookies.get('csrftoken');


// Function to submit the form
const submitForm = () => {
    const form = document.getElementById('logoutForm');
    form.submit();
}

</script>

<template> 
    <form id="logoutForm" method="post" action="/accounts/logout/" class="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-gray-200">
        <input type="hidden" name="csrfmiddlewaretoken" :value="csrftoken">
        <!-- Utiliser un lien pour déclencher la soumission du formulaire -->
        <a href="#" @click.prevent="submitForm" class="text-red-600 font-bold cursor-pointer">Sign out</a>
    </form>
</template>


