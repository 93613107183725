
<script  >
import BaseLayout from '../components/Base.vue'
import Counter from '../components/Counter.vue'
import { isAuthenticated } from '../libs/user';

const fixtures = [
  'pic9.png', 
  'pic1.png', 
  'pic11.png',  
  'pic12.png', 
  'pic8.png', 
  'pic16.png', 
  'pic5.png', 
  'pic13.png',   
  'pic10.png', 
  'pic7.png', 
  'pic14.png', 
  'pic22.png', 
  'pic19.png', 
  'pic2.png', 
  'pic6.png',  
  'pic24.png', 
  'pic25.png',   
  'pic15.png', 
  'pic17.png', 
  'pic18.png', 
  'pic20.png',  
  'pic23.png', 
  
]

//random
fixtures.sort(() => Math.random() - 0.5);



export default {
  components: {
    BaseLayout,
    Counter
  },
  data() {
    return {
      fixtures,
      isUserLoggedIn: isAuthenticated(),
      Kawaixers: 0,
      Views: 0,
      Generated:0

    };
  },
  mounted() { 
    this.calculateNumbers();
  },
  methods:{
    
    calculateNumbers() {
      const start = new Date("2015-01-13")
      const today = new Date()
      const timeDiff = today - start
      const daysElapsed = timeDiff / (1000 * 60 * 60)
      
      const dailyIncreaseKawaixers =  0.12
      this.Kawaixers = Math.round(daysElapsed * dailyIncreaseKawaixers)
      const dailyIncreaseViews =   150
      this.Views = Math.round(daysElapsed * dailyIncreaseViews)
      const dailyIncreaseGenerated =   8
      this.Generated = Math.round(daysElapsed * dailyIncreaseGenerated)

    }
  }
}

</script>

<template>
  <BaseLayout :is-user-logged-in="isUserLoggedIn">

    <main>
      <section class="bg-white dark:bg-gray-900">
        <div class="pt-8 mt-12 md:mt-20 mx-auto max-w-screen-xl text-center   ">

          <!-- News message -->
          <a href="#"
            class=" hidden inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
            role="alert">
            <span class="text-xs bg-primary-600 rounded-full text-white px-4 py-1.5 mr-3">New</span> <span
              class="text-sm font-medium">Kawaix is out! See what's new</span>
            <svg class="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"></path>
            </svg>
          </a>

          <div class="mx-auto md:w-1/2  w-2/3 text-left ">
            <h1
              class="mb-4 sm:text-4xl text-3xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
              Kawai X<br /> Boundless Art<br /> Powered by AI
            </h1>
            <p class="mb-0 text-lg font-normal text-gray-500 lg:text-xl   dark:text-gray-400">
              Give life to your words, turn them into visual marvels.
            </p>
          </div>

          <!-- Login section -->
          <div class="block mt-2 sm:mt-6 text-center">
            <router-link to="/login">
              <div
                class="inline-flex justify-center items-center py-2 sm:py-3 px-4 sm:px-5 text-sm sm:text-base font-medium text-center text-gray-900 rounded-lg border dark:bg-red-700 border-gray-300 hover:bg-red-100 focus:ring-4 focus:ring-red-100 dark:text-white dark:border-red-700 dark:hover:bg-red-600 dark:focus:ring-red-800">
                <svg aria-hidden="true" class="mr-2 w-5 h-5 text-primary-600 dark:text-primary-500" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                    clip-rule="evenodd"></path>
                </svg>
                Let's go
              </div>
            </router-link>
            <!-- Commented out the "Watch gallery" button as per the request. If you want to bring it back later, just uncomment this section. -->
            <!--
              <a href="#" class=" inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                  Watch gallery
                  <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                  </svg>
              </a>
              -->
          </div>


        </div>

        <!-- Stats -->
        <div id="fullWidthTabContent" class="w-full">
          <div class="p-0" id="stats" role="tabpanel" aria-labelledby="stats-tab">
            <dl class="grid md:max-w-screen-lg max-w-screen-sm  grid-cols-3 gap-0 p-2 mx-auto text-gray-900 dark:text-white sm:p-4 md:p-8">
              <div class="flex flex-col items-center justify-center text-center">
                <dt class="mb-2 text-xl sm:text-3xl font-extrabold">
                  <Counter :number="Generated"></Counter>
                </dt>
                <dd class="text-xs sm:text-base text-gray-500 dark:text-gray-400">Generated</dd>
              </div>
              <div class="flex flex-col items-center justify-center text-center">
                <dt class="mb-2 text-xl sm:text-3xl font-extrabold">
                  <Counter :number="Kawaixers"></Counter>
                </dt>
                <dd class="text-xs sm:text-base text-gray-500 dark:text-gray-400">Kawaixers</dd>
              </div>
              <div class="flex flex-col items-center justify-center text-center">
                <dt class="mb-2 text-xl sm:text-3xl font-extrabold">
                  <Counter :number="Views"></Counter>
                </dt>
                <dd class="text-xs sm:text-base text-gray-500 dark:text-gray-400">Views</dd>
              </div>
            </dl>
          </div>
        </div>




        <!-- Gallery -->
        <div
          class="mx-auto text-center w-full relative overflow-x-hidden dark:bg-gray-900 max-h-[900px] overflow-y-hidden">

          <!-- Gradient au bas -->
          <div
            class="absolute bottom-0  left-0 right-0 h-80 bg-gradient-to-t from-black to-transparent dark:from-black  z-10">
          </div>
          <!-- Galerie d'images avec débordement -->

          <div class="flex flex-wrap justify-center items-center mt-2 text-gray-500 sm:justify-between -ml-16 -mr-16">

            <div class="columns-2 md:columns-3 xl:columns-4 2xl:columns-6 3xl:columns-6 4xl:columns-8">

              <div v-for="image in fixtures" :key="image"
                class="relative mb-4 before:content-[''] before:rounded-md before:absolute before:inset-0 before:bg-black before:bg-opacity-20">
                <img class="w-full rounded-md" :src="image">
                <div class="test__body absolute inset-0 p-8 text-white flex flex-col">
                </div>
              </div>

            </div>


          </div>

        </div>

      </section>


    </main>

  </BaseLayout>
</template>
