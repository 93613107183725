<script setup >
// http://127.0.0.1:5173/complete?sub_id=19A3tpTmzlMaKrHw&invoice_id=2&id=q8mlqSIOZQoHj4ZAgrjJHavcu912L2UU6&state=succeeded
import { nextTick, ref, onMounted } from "vue";
import ConfettiExplosion from "vue-confetti-explosion"; 
import { useRoute } from 'vue-router';
 
const route = useRoute();
const isStateSucceeded = ref(false);

onMounted(() => {
  if (route.query.state && route.query.state === 'succeeded') {
    isStateSucceeded.value = true;
  }
});

</script>


<template>
  <div class="min-h-screen flex flex-col items-center justify-center overflow-hidden">
    <div class="  flex justify-center">  
      <ConfettiExplosion v-if="isStateSucceeded" :particleCount="300" :force="0.7" :duration="5000" />
    </div>
    
    <div class="bg-gray-800 mb-4 p-4 rounded-lg mx-10">
      
      <div class="  flex justify-center">
        <!-- Logo -->
        <a href="/editor" class="flex  mr-4">
          <span class="self-center ml-4 mb-1 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 229 235" version="1.1"><path d="M 22 103.477 C 22 147.765, 22.337 183.999, 22.750 183.999 C 23.163 183.998, 25.565 181.860, 28.090 179.249 C 30.614 176.637, 47.898 158.075, 66.497 138 C 85.097 117.925, 103.056 98.564, 106.407 94.975 C 109.758 91.386, 125.775 74.129, 142 56.626 C 158.225 39.123, 171.656 24.380, 171.846 23.863 C 172.057 23.291, 162.784 22.990, 148.100 23.093 C 124.832 23.257, 123.931 23.337, 121.754 25.439 C 120.514 26.636, 115.900 31.427, 111.500 36.086 C 107.100 40.745, 93.375 55.519, 81 68.916 L 58.500 93.274 58 58.387 L 57.500 23.500 39.750 23.227 L 22 22.954 22 103.477" stroke="none" fill="#fe0000" fill-rule="evenodd"/><path d="M 69 156.645 C 47.825 179.506, 28.587 200.291, 26.250 202.834 L 22 207.456 22 216.728 L 22 226 40 226 L 58 226 58.008 211.750 L 58.015 197.500 74.136 180.179 C 83.003 170.652, 90.620 163.227, 91.064 163.679 C 91.507 164.130, 97.862 171.327, 105.185 179.671 C 112.508 188.015, 124.575 201.817, 132 210.342 L 145.500 225.841 175.405 225.920 C 203.790 225.996, 205.234 225.911, 203.822 224.250 C 203.004 223.287, 194.046 213.078, 183.917 201.562 C 173.788 190.046, 162.140 176.771, 158.034 172.062 C 153.927 167.353, 141.821 153.600, 131.131 141.500 C 120.442 129.400, 110.752 118.505, 109.598 117.289 L 107.500 115.079 69 156.645" stroke="none" fill="#E5E7EB" fill-rule="evenodd"/></svg>
          </span>
          <span class="self-center   text-3xl font-semibold whitespace-nowrap dark:text-gray-200">awaix</span>
        </a>
      </div>

    <div v-if="isStateSucceeded">      
      <div class="m-5 text-white flex   text-center">
        Payment succeeded!
      </div>
      <div class="  flex justify-center mt-6">  
        <router-link to="/editor" >
          <button type="button"   class="text-white bg-[#fe0000] hover:bg-[#fe0000]/90 focus:ring-4 focus:outline-none focus:ring-[#fe0000]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#fe0000]/55 mr-2 mb-2"> 
            Start creating!
          </button> 
        </router-link> 
      </div>
    </div>

    <div v-if="!isStateSucceeded">      
      <div class="m-5 text-white flex  text-center">
        Payment failed, please click below to return to plans page.
      </div>
      <div class="  flex justify-center mt-6">  
        <router-link to="/pricing" >
          <button type="button"   class="text-white bg-[#fe0000] hover:bg-[#fe0000]/90 focus:ring-4 focus:outline-none focus:ring-[#fe0000]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#fe0000]/55 mr-2 mb-2"> 
            Retry
          </button> 
        </router-link> 
      </div>
    </div>
 
    </div>
  </div>
</template>

 