
<script  >
import BaseLayout from '../components/Base.vue'
import { isAuthenticated } from '../libs/user';
import ApiClient from '../libs/api.js';

export default {
  components: {
    BaseLayout
  },
  data() {
    return {
      isAuthenticated: isAuthenticated(),
      userProfile: {},
    };
  },

  async mounted() {
    window.addEventListener('resize', this.updateIsMobile)

    try {
      const response = await ApiClient.getProfile()
      this.userProfile = response.data
    } catch (error) {
      console.error(error);
    }
  },
}
</script>


<template>
  <BaseLayout :is-user-logged-in="isAuthenticated">
    <main>

      <section class="flex items-center justify-center h-screen  dark:bg-gray-900">
        <div class="  px-4 mx-auto w-11/12 md:w-1/2  md:max-w-xl   ">
          <div class="  border  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

            <div class="flex flex-col items-center pb-10">
              <img class="w-24 h-24 mb-3 mt-2 rounded-full shadow-lg" :src="userProfile.picture" alt="Bonnie image" />
              <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{{ userProfile.name }}</h5>
              <span class="text-sm mb-8  text-gray-500 dark:text-gray-400">{{ userProfile.email }}</span>

              <button type="button"
                class="relative flex items-center justify-between py-2.5 px-5 mr-2 mb-2 w-11/12  text-left text-sm font-medium text-gray-900 focus:outline-none uppercase bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                <div>
                  <p class="text-xs text-gray-600 leading-tight">Your plan</p>
                  <p class="mt-1">Free</p>
                </div>
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                  fill="none" viewBox="0 0 21 21">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="m6.072 10.072 2 2 6-4m3.586 4.314.9-.9a2 2 0 0 0 0-2.828l-.9-.9a2 2 0 0 1-.586-1.414V5.072a2 2 0 0 0-2-2H13.8a2 2 0 0 1-1.414-.586l-.9-.9a2 2 0 0 0-2.828 0l-.9.9a2 2 0 0 1-1.414.586H5.072a2 2 0 0 0-2 2v1.272a2 2 0 0 1-.586 1.414l-.9.9a2 2 0 0 0 0 2.828l.9.9a2 2 0 0 1 .586 1.414v1.272a2 2 0 0 0 2 2h1.272a2 2 0 0 1 1.414.586l.9.9a2 2 0 0 0 2.828 0l.9-.9a2 2 0 0 1 1.414-.586h1.272a2 2 0 0 0 2-2V13.8a2 2 0 0 1 .586-1.414Z" />
                </svg>
              </button>

              <router-link to="/pricing"
                class="relative mt-4 flex items-center justify-between   mr-2 mb-2 w-11/12  text-left text-sm font-medium text-gray-900 focus:outline-none   rounded-lg border  focus:z-10 focus:ring-4  dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                <button type="button"
                  class="w-full relative mt-4 flex items-center justify-between py-2.5 px-5 mr-2 mb-2 uppercase  text-left text-sm font-medium">
                  <div>
                    <p class="text-xs text-gray-600 leading-tight">upgrade membership</p>
                    <p class="mt-1">view membership plans</p>
                  </div>
                  <svg class="w-6 h-6 text-gray-800 dark:text-green-400" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 5 5 1 1 5m8 6L5 7l-4 4" />
                  </svg>
                </button>
              </router-link>


            </div>
          </div>
        </div>

      </section>
    </main>
  </BaseLayout>
</template>
