
import axios from 'axios'

export function isMobile() {
  return window.matchMedia('(max-width: 768px)').matches
}

export function slugify(text) {
  return text.toLowerCase().replace(/[^\w\s-]/g, '').replace(/\s+/g, '-');
}

export async function downloadImage(url, title) {
  var response = await axios.get(url, {
    responseType: "arraybuffer"
  });

  var arrayBuffer = response.data;
  var blob = new Blob([arrayBuffer], { type: "image/png" });

  var link = document.createElement("a");
  link.href = URL.createObjectURL(blob);

  var sluggedSentence = slugify(title).substring(0, 16);
  var currentDateTime = new Date().toISOString().replace(/[-:.]/g, '').replace('T', '_').replace('Z', '');
  var filename = `${sluggedSentence}_${currentDateTime}.png`;

  link.download = filename
  link.style.display = "none";

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
}
