import axios from 'axios';
import Cookies from 'js-cookie';

function csrfSafeMethod(method) {
  // These HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

// make sure we inject csrf token into every request that needs it
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.request.use(config => {
  if (!csrfSafeMethod(config.method) && !config.crossDomain) {
    config.headers['X-CSRFToken'] = Cookies.get('csrftoken');
  }
  return config;
});

// if the csrf token expires, we refresh it and we try the request again
axios.interceptors.response.use(e => e, async error => {
  const resp = error?.response;
  if (resp?.status === 403 && resp?.response?.data?.toLowerCase()?.includes("csrf")) {
    await populateCSRFToken();
    return axios(error.config);
  }
  return Promise.reject(error);
});

// Axios response interceptor
axios.interceptors.response.use(response => {
  return response;
}, async error => {
  if (!error.response) {
    return Promise.reject(error);
  }

  // If the error is a 403 Forbidden, it might be due to an expired CSRF token
  if (error.response.status === 403) {
    try {
      await populateCSRFToken(); // Refresh the CSRF token

      // Retry the original request. Note that error.config contains all the information
      // needed to retry the request (method, url, data, etc.)
      return axios(error.config);
    } catch (refreshError) {
      return Promise.reject(refreshError);
    }
  }

  return Promise.reject(error);
});

// If we are not logged in, redirect to the login page
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default {
  populateCSRFToken() {
    // just for the side effect
    return axios.get('/accounts/login/forms/google/');
  },
  getCreditsBalance() {
    // returns:
    // [
    //     {"type": "normal", "balance": 1000},
    //     {"type": "fast", "balance": 0}
    //
    // ]
    return axios.get('/api/v1/me/credits');
  },

  getProfile() {
    // returns:
    // {  "id": "0189e0cf-181f-a2d0-4842...",
    //    "name": "Jean Bono",
    //    "picture": "https://lh3.googleusercontent.com/a/....,
    //    "email": "jean.bono@gmail....",
    //    "locale": "en"
    // }

    return axios.get('/api/v1/me/profile');
  },

  requestImageGeneration(prompt, negativePrompt, aspectRatio, style) {
    // returns:
    // {
    //   "type": "delivery",
    //   "id": "74896363824...",
    // }
    return axios.post('/api/v1/generation/images/request', {
      prompt,
      aspect_ratio: aspectRatio,
      negative_prompt: negativePrompt,
      style
    });
  },

  getDeliveryStatus(deliveryId) {
    // returns:
    // {
    //   "generation_id": "74896363824...",
    //   "started_at": "2021-03-28T15:00:00Z",
    //   "finished_at": "2021-03-28T15:00:00Z",
    //   "error": null,
    // }
    return axios.get(`/api/v1/generation/images/delivery/${deliveryId}`);
  },

  getGeneratedImage(generationId) {
    // returns:
    // {
    //   "prompt": "A photo of a cat",
    //   "negative_prompt": "A photo of a dog",
    //   "aspect_ratio": "portrait", // or "square", "landscape"
    //   "images_urls": [
    //     "https://...",
    //     "https://...",
    //     "https://...",
    //   ]
    // }
    return axios.get(`/api/v1/generation/images/${generationId}`);
  },

  getLastGeneratedImages(count) {
    // returns:
    // [{
    //   "prompt": "A photo of a cat",
    //   "negative_prompt": "A photo of a dog",
    //   "aspect_ratio": "portrait", // or "square", "landscape"
    //   "images_urls": [
    //     "https://...",
    //     "https://...",
    //     "https://...",
    //   ]
    // },
    // ...]
    const params = count ? { count } : {};
    return axios.get(`/api/v1/generation/images/`, params);
    // Max count is 50
  }
}
