<script>

export default {
  props: ['generatedImages', 'openWithUrl'],
  emits: ['close-mod'],
  data() {
    return {
      activeImage: null,
    }
  },
  methods: {
    nextImage() {
      const currentIndex = this.generatedImages.findIndex(img => img.images_urls[0] === this.activeImage);
      const nextIndex = (currentIndex + 1) % this.generatedImages.length;
      this.activeImage = this.generatedImages[nextIndex].images_urls[0];
    },
    prevImage() {
      const currentIndex = this.generatedImages.findIndex(img => img.images_urls[0] === this.activeImage);
      const prevIndex = (currentIndex - 1 + this.generatedImages.length) % this.generatedImages.length;
      this.activeImage = this.generatedImages[prevIndex].images_urls[0];
    },
  }
}
</script>

<template>
  <!-- Modal Viewer Carousel -->
  <div v-if="openWithUrl" class="fixed inset-0 z-50 flex items-center justify-center" @click="$emit('close-mod')">
    <div class="absolute inset-0 bg-black opacity-80"></div>

    <!-- Image Viewer Carousel -->
    <div id="gallery" class="relative w-full" data-carousel="slide">


      <!-- Carousel wrapper with download button -->
      <div class="relative   rounded-lg group cursor-pointer flex justify-center items-center">
        <img :src="activeImage || openWithUrl" @click.stop=""
          class="md:rounded-lg transition-opacity group-hover:opacity-80" alt="">

        <!-- Download icon/button -->
        <div
          class="absolute bottom-4 left-1/1.9  group-hover:opacity-100 transition-opacity bg-gray-500 hover:bg-gray-700 opacity-60 rounded-lg md:px-6 md:py-4 px-4 py-4 ">
          <a :href="activeImage" download class=" ">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="currentColor" viewBox="0 0 20 20">
              <path
                d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
              <path
                d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
            </svg>
          </a>
        </div>
      </div>


      <!-- Slider controls -->
      <button @click.stop="prevImage" type="button"
        class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        data-carousel-prev>
        <span
          class="inline-flex items-center justify-center w-16 h-16 md:w-20 md:h-20 rounded-full  dark:bg-gray-500/30   dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
          <svg class="w-4 h-4   dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M5 1 1 5l4 4" />
          </svg>
          <span class="sr-only">Previous</span>
        </span>
      </button>

      <button @click.stop="nextImage" type="button"
        class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        data-carousel-next>
        <span
          class="inline-flex items-center justify-center w-16 h-16 md:w-20 md:h-20 rounded-full  dark:bg-gray-500/30  dark:group-hover:bg-gray-800/60 group-focus:ring-4   dark:group-focus:ring-gray-800/70 group-focus:outline-none">
          <svg class="w-4 h-4   dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 9 4-4-4-4" />
          </svg>
          <span class="sr-only">Next</span>
        </span>
      </button>

    </div>

    <!-- Close button -->
    <button
      class="absolute md:top-14 md:right-14 top-4 right-4  z-20 px-4 text-white dark:hover:bg-gray-500 bg-gray-800 rounded-full p-2">X</button>
  </div>
</template>
