<script   >
import BaseLayout from '../components/Base.vue'
import { isAuthenticated } from '../libs/user';
 


export default {
  components: {
    BaseLayout
  },
  data() {
    return { 
      isUserLoggedIn: isAuthenticated(),
    };
  }
}

</script>


<template>
    <BaseLayout :is-user-logged-in="isUserLoggedIn">
      <div class="mt-16 flex flex-col items-center justify-center text-gray-300">  
  
        <div class="max-w-4xl mx-auto p-6">
          <h1 class="text-3xl font-bold mb-4 text-center">Kawaix Privacy Policy</h1>
          
          <p class="text-gray-500 mb-4">This policy outlines Kawaix's practices concerning the collection, usage, and safeguarding of your personal data. Your engagement with our platform implies consent to these practices.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">1. Personal Data Collection</h2>
          <p class="text-gray-500 mb-4">When registering or signing in via Google, we procure your email. Additionally, any voluntarily provided data, including contact details, are stored.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">2. Data on Image Generation</h2>
          <p class="text-gray-500 mb-4">All images generated are collected and visible for human moderation and to future improve our moderation and generation system. We retain discretionary rights to employ these images, including for promotional activities.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">3. Utilization of Personal Data</h2>
          <p class="text-gray-500 mb-4">Your email facilitates authentication and platform updates. Your data might also be used for communication or legal compliance, and not otherwise without express permission.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">4. Rationale Behind Data Collection</h2>
          <p class="text-gray-500 mb-4">We gather data for authentication, communication, and compliance with statutory mandates, as well as to uphold our vested interests.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">5. Access to Your Personal Data</h2>
          <p class="text-gray-500 mb-4">Access is confined to our internal teams. We staunchly oppose selling or disseminating your data, barring legal imperatives or with explicit consent.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">6. Safeguarding Your Personal Data</h2>
          <p class="text-gray-500 mb-4">Adopting stringent measures, we strive to shield your data from unauthorized breaches. Limited access is granted solely to pertinent personnel.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">7. Retention Period of Your Personal Data</h2>
          <p class="text-gray-500 mb-4">Your data remains with us indefinitely, barring deletion requests or legally mandated retention durations.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">8. About Device Fingerprinting</h2>
          <p class="text-gray-500 mb-4">This entails discerning unique device attributes to bolster security. Utilized for anti-fraudulence, it helps deter malicious actions like promotion manipulation.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">9. Device Fingerprinting Storage & Usage</h2>
          <p class="text-gray-500 mb-4">Data sourced from fingerprinting assures platform security and curbs fraud. It is stored securely.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">10. User Rights Pertaining to Data</h2>
          <p class="text-gray-500 mb-4">You possess rights like data access, correction, erasure, and processing objection. Address such requests to <a href="mailto:contact@kawaix.com" class="text-[#fe0000] underline">contact@kawaix.com</a>.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">11. Cookies & Analogous Mechanisms</h2>
          <p class="text-gray-500 mb-4">We might deploy cookies to assimilate data for enhancing user experience and relevant promotions. Cookie usage can be regulated browser-wise.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">12. Legal Compliance Assurance</h2>
          <p class="text-gray-500 mb-4">We pledge adherence to prevalent data protection statutes, encompassing GDPR and CCPA.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">13. Limitations Pertaining to Liabilities</h2>
          <p class="text-gray-500 mb-4">Kawaix bears no responsibility for damages stemming from data misuse or unauthorized disclosures, barring legal obligations.</p>
  
          <h2 class="text-l font-semibold mt-6 mb-2">14. Queries & Concerns</h2>
          <p class="text-gray-500 mb-4">For any clarifications regarding our privacy protocols, reach out at <a href="mailto:contact@kawaix.com" class="text-[#fe0000] underline">contact@kawaix.com</a>.</p>
  
        </div> 
      </div>
    </BaseLayout>
  </template>
  
 