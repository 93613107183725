<script>
import BaseLayout from '../components/Base.vue'
import { isAuthenticated } from '../libs/user';

const paymentAccount = "kawai-test"

export default {
  components: {
    BaseLayout
  },
  data() {
    return {
      paymentAccount: paymentAccount,
      isUserLoggedIn: isAuthenticated(),
    };
  },
  created() {
    // ChargeBee payment system required
    const script = document.createElement('script');
    script.src = "https://js.chargebee.com/v2/chargebee.js";
    script.setAttribute('data-cb-site', this.paymentAccount);

    // Utilisation d'un écouteur d'événement pour savoir quand le script est chargé
    script.onload = this.initializeChargebee;

    document.body.appendChild(script);

    console.log("Payment processor loaded");
  },
  methods: {
    initializeChargebee() {
      if (window.Chargebee) {
        const chargebeeInstance = Chargebee.init({
          site: this.paymentAccount
        });

      }
    }
  }
}
</script>


<template>
  <BaseLayout :is-user-logged-in="isUserLoggedIn">
    <main>
      <section class="bg-white dark:bg-gray-900 mt-10">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-gray-200">Embrace the Future
              with
              Kawai X</h2>
            <p class="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Discover digital creation without
              borders. Dive into unparalleled excellence. Choose your plan, and elevate your journey with Kawai X.</p>
          </div>

          <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
            <!-- Pricing Card -->
            <div
              class="flex flex-col justify-center  items-center  p-6 mx-auto text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow-sm dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-gray-200">

              <h3 class="mb-4 text-2xl font-semibold">Lite Render</h3>
              <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">Quickstart your AI-driven visual journey.
              </p>
              <div class="flex justify-center items-baseline my-8">
                <span class="mr-2 text-5xl font-extrabold">$9.99</span>
                <span class="text-gray-500 dark:text-gray-400">/month</span>
              </div>
              <!-- List -->
              <ul role="list" class="mb-8 mt-4 space-y-4 text-left md:text-xl">
                <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="w-6 h-6 text-green-500 dark:text-green-500 " aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M1 5.917 5.724 10.5 15 1.5" />
                  </svg>
                  <span>Simultaneous requests: <span class="text-red-700 text-2xl font-bold">1</span> </span>
                </li>
                <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="w-6 h-6 text-green-500 dark:text-green-500" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M1 5.917 5.724 10.5 15 1.5" />
                  </svg>
                  <span>Credits Per Month: <span class="text-red-700 text-2xl font-bold">1000</span></span>
                </li>
                <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="w-6 h-6 text-red-700 dark:text-red-700" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span>Commercial Use Allowed</span>
                </li>
              </ul>
              <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="Lite-Render-EUR-Monthly"
                class="text-white uppercase  w-11/12 bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-gray-200 dark:hover:bg-red-800 dark:focus:ring-red-900">Buy
                Now</a>

            </div>
            <!-- Pricing Card -->
            <div
              class="flex flex-col  justify-center  items-center  p-8 mx-auto  transform lg:scale-105   text-center text-gray-900 bg-white rounded-lg border shadow-xl dark:border-gray-600 dark:bg-gray-800 dark:text-gray-200">


              <span
                class="bg-pink-100 mb-2 text-pink-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-pink-900 dark:text-pink-300">Best
                Value</span>

              <h3 class="mb-4 text-2xl font-semibold">Pro Render</h3>
              <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">Accelerate creation with priority
                processing
                & added quotas.</p>
              <div class="flex justify-center items-baseline my-8">
                <span class="mr-2 text-5xl font-extrabold">$24.99</span>
                <span class="text-gray-500 dark:text-gray-400" dark:text-gray-400>/month</span>
              </div>
              <!-- List -->
              <ul role="list" class="mb-8 mt-4 space-y-4 text-left md:text-xl">
                <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="w-6 h-6 text-green-500 dark:text-green-500 " aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M1 5.917 5.724 10.5 15 1.5" />
                  </svg>
                  <span>Simultaneous requests: <span class="text-red-700 text-2xl font-bold">2</span> </span>
                </li>
                <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="w-6 h-6 text-green-500 dark:text-green-500" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M1 5.917 5.724 10.5 15 1.5" />
                  </svg>
                  <span>Credits Per Month: <span class="text-red-700 text-2xl font-bold">3000</span></span>
                </li>
                <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="w-6 h-6 text-green-500 dark:text-green-500" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M1 5.917 5.724 10.5 15 1.5" />
                  </svg>
                  <span>Commercial Use Allowed</span>
                </li>
              </ul>
              <a href="#"
                class="text-white uppercase   bg-red-600 hover:bg-red-700  w-11/12 focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-gray-200 dark:hover:bg-red-800 dark:focus:ring-red-900">Buy
                Now</a>
            </div>
            <!-- Pricing Card -->
            <div
              class="flex flex-col p-6 mx-auto  justify-center  items-center text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow-sm dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-gray-200">

              <h3 class="mb-4 text-2xl font-semibold">Mega Render</h3>
              <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">Unleash AI at scale. Maximum speed,
                unlimited requests.</p>
              <div class="flex justify-center items-baseline my-8">
                <span class="mr-2 text-5xl font-extrabold">$49.99</span>
                <span class="text-gray-500 dark:text-gray-400">/month</span>
              </div>
              <!-- List -->
              <ul role="list" class="mb-8 mt-4 space-y-4 text-left md:text-xl">
                <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="w-6 h-6 text-green-500 dark:text-green-500 " aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M1 5.917 5.724 10.5 15 1.5" />
                  </svg>
                  <span>Simultaneous requests: <span class="text-red-700 text-2xl font-bold">4</span> </span>
                </li>
                <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="w-6 h-6 text-green-500 dark:text-green-500" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M1 5.917 5.724 10.5 15 1.5" />
                  </svg>
                  <span>Credits Per Month: <span class="text-red-700 text-2xl font-bold">6000</span></span>
                </li>
                <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="w-6 h-6 text-green-500 dark:text-green-500" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M1 5.917 5.724 10.5 15 1.5" />
                  </svg>
                  <span>Commercial Use Allowed</span>
                </li>
              </ul>
              <a href="#"
                class="text-white uppercase   bg-red-600 hover:bg-red-700  w-11/12 focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-gray-200 dark:hover:bg-red-800 dark:focus:ring-red-900">Buy
                Now</a>
            </div> 
          </div>
 

          <!-- FAQ -->
          <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12 mt-16">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-gray-200">Frequently Asked
              Questions</h2>
          </div>


          <div id="accordion-flush" data-accordion="open" data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400" >
            <h2 id="accordion-flush-heading-1">
                  <button type="button" class="flex items-center justify-between w-full   font-medium text-left text-gray-500   border-gray-200 dark:border-gray-700 dark:text-gray-400" data-accordion-target="#accordion-flush-body-1" aria-expanded="true" aria-controls="accordion-flush-body-1">

                <span class="flex items-center text-lg font-bold mt-4"> 
                  Do I need to purchase a plan to use the site?
                </span>
                 
              </button>
            </h2>  
            <div id="accordion-flush-body-1"   aria-labelledby="accordion-flush-heading-1">
              <div class="    border-gray-200 dark:border-gray-700">
                <p class="mb-2 text-gray-500 dark:text-gray-400">No, you can use the site for free. However, you will be
                  limited to 100 slow credits per day. If you would like more than that, or to access fast credits, you
                  will need to purchase a plan.</p>
              </div>
            </div>

            <h2 id="accordion-open-heading-2" data-accordion="collapse" data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
              <button type="button"
              class="flex items-center justify-between w-full   font-medium text-left text-gray-500   border-gray-200 dark:border-gray-700 dark:text-gray-400" 
                data-accordion-target="#accordion-open-body-2" aria-expanded="true" aria-controls="accordion-open-body-2">
                <span class="flex items-center text-lg font-bold mt-4"> 
                  How many images can I create with a credit?
                </span>
                 
              </button>
            </h2>
            <div id="accordion-open-body-2"   aria-labelledby="accordion-open-heading-2">
              <div class="    border-gray-200 dark:border-gray-700">
                <p class="mb-2 text-gray-500 dark:text-gray-400">You can create one image with one credit. Currently all
                  images are the same price, but in the future we may offer different prices for different types of
                  generation requests. For example, a 4K image may cost more than a 960p image, an upscale might cost more
                  than a raw generation, etc.</p>
              </div>
            </div>
            <h2 id="accordion-open-heading-3" data-accordion="collapse" data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
              <button type="button"
              class="flex items-center justify-between w-full   font-medium text-left text-gray-500   border-gray-200 dark:border-gray-700 dark:text-gray-400" 
                data-accordion-target="#accordion-open-body-3" aria-expanded="true" aria-controls="accordion-open-body-3">
                <span class="flex items-center text-lg font-bold mt-4"> 
                  Can I use the images I create commercially?
                </span>
                 
              </button>
            </h2>
            <div id="accordion-open-body-3"   aria-labelledby="accordion-open-heading-3">
              <div class="    border-gray-200 dark:border-gray-700">
                <p class="mb-2 text-gray-500 dark:text-gray-400">If you are a Pro or Premium user, you can use the images
                  you create commercially. If you are a free user, you can only use the images you create for personal use
                  only.</p>
              </div>
            </div>
            <h2 id="accordion-open-heading-4" data-accordion="collapse" data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
              <button type="button"
              class="flex items-center justify-between w-full   font-medium text-left text-gray-500   border-gray-200 dark:border-gray-700 dark:text-gray-400" 
                data-accordion-target="#accordion-open-body-4" aria-expanded="true" aria-controls="accordion-open-body-4">
                <span class="flex items-center text-lg font-bold mt-4"> Can I cancel my subscription?</span>
                 
              </button>
            </h2>
            <div id="accordion-open-body-4"   aria-labelledby="accordion-open-heading-4">
              <div class="    border-gray-200 dark:border-gray-700">
                <p class="mb-2 text-gray-500 dark:text-gray-400">Yes, you can cancel your subscription at any time, simply
                  go to the account page and choose cancel subscription. If you cancel your subscription, you will still
                  have access to the plan until the end of the billing period. For example, if you cancel your
                  subscription
                  on the 15th of the month, you will still have access to the plan until your plan was set to renew.</p>
              </div>
            </div>
            <h2 id="accordion-open-heading-5 " data-accordion="collapse" data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
              <button type="button"
              class="flex items-center justify-between w-full   font-medium text-left text-gray-500   border-gray-200 dark:border-gray-700 dark:text-gray-400" 
                data-accordion-target="#accordion-open-body-5" aria-expanded="false"
                aria-controls="accordion-open-body-5">
                <span class="flex items-center text-lg font-bold mt-4"> Can I change my subscription?</span>
                 
              </button>
            </h2>
            <div id="accordion-open-body-5"   aria-labelledby="accordion-open-heading-5">
              <div class="    border-gray-200 dark:border-gray-700">
                <p class="mb-2 text-gray-500 dark:text-gray-400">Yes, you can change your subscription at any time, simply
                  go to the account page and choose manage subscription. If you upgrade your subscription, you will be
                  charged the difference between the two plans. If you downgrade your subscription, you will keep your
                  subscription level for the remainder of the current billing term and at the end be downgraded to the
                  appropriate level.</p>
              </div>
            </div>
          </div>
        </div>



      </section>
    </main>
  </BaseLayout>
</template>
