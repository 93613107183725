
<script  >
import UserMenu from '../components/UserMenu.vue'



export default {
  name: 'BaseLayout',
  components: {
    UserMenu
  },
  props: {
    isUserLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showTooltip: false,
      isOpen: false,
      isMobile: false,
      footerHeight: 0,
      isMobileMenuOpen: false,
    };
  },
}

</script>

<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 flex flex-col h-screen">

    <!-- Top navigation bar -->
    <nav
      class="bg-white border border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-50 ">
      <div class="flex flex-wrap justify-between   items-center">
        <div class="flex justify-start items-center ">

          <!-- Burger Menu  -->
          <div class="md:hidden cursor-pointer" @click="isMobileMenuOpen = !isMobileMenuOpen">
            <div class="w-5 h-0.5 bg-gray-300 mb-1"></div>
            <div class="w-5 h-0.5 bg-gray-300 mb-1"></div>
            <div class="w-5 h-0.5 bg-gray-300"></div>
          </div>

          <!-- Logo -->
          <router-link to="/" class="flex items-center justify-between mr-4">
            <span class="self-center ml-4 mb-1 ">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 229 235" version="1.1">
                <path
                  d="M 22 103.477 C 22 147.765, 22.337 183.999, 22.750 183.999 C 23.163 183.998, 25.565 181.860, 28.090 179.249 C 30.614 176.637, 47.898 158.075, 66.497 138 C 85.097 117.925, 103.056 98.564, 106.407 94.975 C 109.758 91.386, 125.775 74.129, 142 56.626 C 158.225 39.123, 171.656 24.380, 171.846 23.863 C 172.057 23.291, 162.784 22.990, 148.100 23.093 C 124.832 23.257, 123.931 23.337, 121.754 25.439 C 120.514 26.636, 115.900 31.427, 111.500 36.086 C 107.100 40.745, 93.375 55.519, 81 68.916 L 58.500 93.274 58 58.387 L 57.500 23.500 39.750 23.227 L 22 22.954 22 103.477"
                  stroke="none" fill="#fe0000" fill-rule="evenodd" />
                <path
                  d="M 69 156.645 C 47.825 179.506, 28.587 200.291, 26.250 202.834 L 22 207.456 22 216.728 L 22 226 40 226 L 58 226 58.008 211.750 L 58.015 197.500 74.136 180.179 C 83.003 170.652, 90.620 163.227, 91.064 163.679 C 91.507 164.130, 97.862 171.327, 105.185 179.671 C 112.508 188.015, 124.575 201.817, 132 210.342 L 145.500 225.841 175.405 225.920 C 203.790 225.996, 205.234 225.911, 203.822 224.250 C 203.004 223.287, 194.046 213.078, 183.917 201.562 C 173.788 190.046, 162.140 176.771, 158.034 172.062 C 153.927 167.353, 141.821 153.600, 131.131 141.500 C 120.442 129.400, 110.752 118.505, 109.598 117.289 L 107.500 115.079 69 156.645"
                  stroke="none" fill="#E5E7EB" fill-rule="evenodd" />
              </svg>
            </span>
            <span class="self-center   text-3xl font-semibold whitespace-nowrap dark:text-gray-200">awai X</span>
          </router-link>


        </div>
        <div class="flex  md:order-2">
          <div v-if="isUserLoggedIn">
            <!-- User's infos -->
            <UserMenu />
          </div>
        </div>

        <div class="hidden justify-between items-center w-full md:flex md:w-auto md:order-1">
          <ul class="flex flex-col mt-4 font-medium md:flex-row md:space-x-8 md:mt-0">
            <li>
              <router-link to="/"
                class="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 md:bg-transparent md:text-primary-700 md:p-0 dark:text-white"
                :class="{ 'font-semibold underline': $route.path === '/' }">Home</router-link>
            </li>
            <li>
              <router-link to="/pricing"
                class="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 md:bg-transparent md:text-primary-700 md:p-0 dark:text-white"
                :class="{ 'font-semibold underline': $route.path === '/pricing' }">
                Pricing</router-link>
            </li>
            <li v-if="isUserLoggedIn">
              <router-link to="/editor"
                class="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 md:bg-transparent md:text-primary-700 md:p-0 dark:text-white"
                :class="{ 'font-semibold underline': $route.path === '/editor' }">

                Editor
              </router-link>
            </li>
            <li v-if="isUserLoggedIn">
              <router-link to="/account"
                class="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 md:bg-transparent md:text-primary-700 md:p-0 dark:text-white"
                :class="{ 'font-semibold underline': $route.path === '/account' }">

                Account
              </router-link>
            </li>
            <li v-if="isUserLoggedIn">
              <router-link to="/gallery"
                class="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 md:bg-transparent md:text-primary-700 md:p-0 dark:text-white"
                :class="{ 'font-semibold underline': $route.path === '/gallery' }">

                Gallery
              </router-link>
            </li>
          </ul>
        </div>

      </div>
    </nav>

    <div v-if="isMobileMenuOpen"
      class="fixed top-0 left-0 z-50 w-full h-1/2 border-b-2 border-gray-700 overflow-y-auto bg-gray-900 text-white">

      <div class="w-full bg-gray-800 p-4 sticky top-0 flex justify-end items-center">

        <button @click="isMobileMenuOpen = false" class="p-2 rounded-md hover:bg-gray-700 focus:outline-none focus:ring">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

      </div>

      <div class="flex flex-col justify-center h-3/4 px-4 space-y-6">
        <ul class="flex flex-col space-y-8 items-center">

          <li>
            <router-link to="/"
              class="px-4 py-2 rounded bg-white text-black text-lg font-bold w-full text-center">Home</router-link>
          </li>

          <li>
            <router-link to="/pricing"
              class="px-4 py-2 rounded bg-white text-black text-lg font-bold w-full text-center">Pricing</router-link>
          </li>

          <li v-if="isUserLoggedIn">
            <router-link to="/editor"
              class="px-4 py-2 rounded bg-white text-black text-lg font-bold w-full text-center">Editor</router-link>
          </li>

          <li v-if="isUserLoggedIn">
            <router-link to="/account"
              class="px-4 py-2 rounded bg-white text-black text-lg font-bold w-full text-center">Account</router-link>
          </li>

          <li v-if="isUserLoggedIn">
            <router-link to="/gallery"
              class="px-4 py-2 rounded bg-white text-black text-lg font-bold w-full text-center">Gallery</router-link>
          </li>

        </ul>
      </div>
    </div>

    <!-- If you remove this div, move the style back to ref="mainContent" in editor vue
      and test that the auto scroll works in the editor when you create a new image
    -->
    <div class="flex-grow  overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-md	scrollbar-corner-gray-600   scrollbar-thumb-gray-500 scrollbar-track-gray-800  " @click="isMobileMenuOpen = false">
      <slot></slot>
    </div>

  </div>
</template>



