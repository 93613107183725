<script  >
import BaseLayout from '../components/Base.vue'
import { isAuthenticated } from '../libs/user';
import apiClient from '../libs/api';
import Carousel from '../components/Carousel.vue';

export default {
  components: {
    BaseLayout,
    Carousel
  },
  data() {
    return {
      isUserLoggedIn: isAuthenticated(),
      generatedImages: [],
      imageToShow: ""
    };
  },
  async mounted() {
    try {
      const response = await apiClient.getLastGeneratedImages(1000);
      this.generatedImages = response.data
    } catch (error) {
      console.error(error.response);
      alert("Something unexpected happened. Please try again later.")
    }
  },

}


</script>

<template>
  <BaseLayout :is-user-logged-in="isUserLoggedIn">
    <main>
      <section class="dark:bg-gray-900 mt-10">
        <div class="py-8 px-4 mx-auto   lg:py-16 lg:px-6   items-center justify-center">
          <div class="  text-center w-full dark:text-gray-200">
            <div class="md:text-4xl mb-2 font-bold">
              My Gallery
            </div>

            <!-- No images -->
            <div v-if="!generatedImages.length">
              <div class="md:text-xl   max-w-screen-sm mx-auto mb-24">
                Ah, Leonardo, a canvas untouched! Let your genius flow, and grace us with your masterpieces.
              </div>

              <router-link to="/editor"
                class="text-white uppercase  bg-red-600 hover:bg-red-700 w-full focus:ring-4 focus:ring-red-200 font-medium rounded-lg   px-8 py-3 text-center dark:text-gray-200 dark:hover:bg-red-800 dark:focus:ring-red-900">Start
                Creating
              </router-link>
            </div>
          </div>

          <!-- Gallery -->
          <div v-if="generatedImages.length" class="mx-auto w-full overflow-x-hidden dark:bg-gray-900">
            <div class="flex flex-wrap justify-center items-center mt-8 text-gray-500 sm:justify-between">
              <!-- Images Container -->
              <div class="flex flex-wrap">
                <div v-for="generation in generatedImages" :key="generation.id" class="w-1/2 md:w-1/5 lg:w-1/7 p-2">
                  <div v-for="url in generation.images_urls" :key="url" class="cursor-pointer relative mb-4 w-full">
                    <img class="w-full rounded-md" :src="url" @click="imageToShow = url">
                  </div>
                </div>
              </div>
            </div>
          </div>
 
        </div>

        <!-- Modal Viewer Carousel -->
        <carousel :open-with-url="imageToShow" @close-mod="imageToShow = ''" :generated-images="generatedImages">
        </carousel>
 

      </section>
    </main>
  </BaseLayout>
</template>
