<script  >
import LogOutForm from './LogOutForm.vue';
import { isMobile } from '../libs/utils';
import ApiClient from '../libs/api.js';

export default {
  components: {
    LogOutForm
  },

  watch: {
    IsUserMenuOpen(newVal) {
      if (newVal) {
        document.addEventListener('click', this.outsideClickListener);
      } else {
        document.removeEventListener('click', this.outsideClickListener);
      }
    },
  },

  async mounted() {
    window.addEventListener('resize', this.updateIsMobile)

    try {
      const response = await ApiClient.getProfile()
      this.userProfile = response.data
    } catch (error) {
      console.error(error);
    }

  },

  unmounted() {
    window.removeEventListener('resize', this.updateIsMobile)
  },

  data() {
    return {
      IsUserMenuOpen: false,
      isMobile: isMobile(),
      userProfile: {},
    };
  },

  methods: {
    toggleUserMenu() {
      this.IsUserMenuOpen = !this.IsUserMenuOpen
      event.stopPropagation();
    },
    outsideClickListener(event) {
      if (!this.$el.contains(event.target)) {
        this.IsUserMenuOpen = false;
      }
    },
    updateIsMobile() {
      this.isMobile = isMobile()
    },
  },


}

</script>

<template>
  <button type="button" @click="toggleUserMenu()" class="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0  "
    id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
    <span class="sr-only">Open user menu</span>
    <img class="w-8 h-8 rounded-full" :src="userProfile.picture" alt="user photo" />
    <div class="flex items-center justify-between font-medium dark:text-white p-2 text-gray-100">
      <span v-if="!isMobile">
        {{ userProfile.name }}
      </span>
      <svg class="h-4 w-4 transform transition-transform duration-150" :class="{ 'rotate-180': IsUserMenuOpen }"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path
          d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
      </svg>
    </div>
  </button>
  <div v-if="IsUserMenuOpen"
    class="absolute   right-2 z-50  my-1  w-56 text-base list-none bg-white  divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 rounded-xl "
    id="dropdown">
    <ul class=" text-gray-700 dark:text-gray-300 rounded-t-xl overflow-hidden " aria-labelledby="dropdown">

      <li>
        <router-link to="/account"
          class="flex justify-between items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-gray-200">Account
          settings</router-link>
      </li>
      <li>
        <router-link to="/pricing" class="flex justify-between items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600
            dark:hover:text-gray-200 ">
          <span class=" flex items-center">
            <svg aria-hidden="true" class="mr-2 w-5 h-5 text-primary-600 dark:text-primary-500" fill="currentColor"
              viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                clip-rule="evenodd"></path>
            </svg>
            Get more credits!
          </span>
        </router-link>
      </li>
    </ul>
    <ul class="py-1 text-gray-700 dark:text-gray-300" aria-labelledby="dropdown">

      <li>
        <router-link to="/tos">
          <a href="#"
            class="flex  items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-gray-200">
            Terms and Conditions</a>
        </router-link>
      </li>
      <li>
        <router-link to="/privacy">
          <a href="#"
            class="flex  items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-gray-200">
            Privacy Policies</a>
        </router-link>
      </li>
      <!-- <li>
        <a href="#"
          class="flex  items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-gray-200">
          Content Guidelines</a>
      </li> -->
    </ul>
    <ul class="  text-gray-700 dark:text-gray-300 rounded-b-xl overflow-hidden" aria-labelledby="dropdown">
      <li>
        <LogOutForm></LogOutForm>
      </li>
    </ul>
  </div>
</template>
