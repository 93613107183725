

<script setup>
import SignInForm from '../components/SignInForm.vue';
import LogoutForm from '../components/LogOutForm.vue';
import { isAuthenticated } from '../libs/user.js';
import { useRouter } from 'vue-router';

// Redirect to editor once logged
const router = useRouter();

if (isAuthenticated()) {
  router.push('/editor');
}
</script>

<template>
  <main>
    <div v-if="!isAuthenticated()">
      <h1>Sign In</h1>
      <SignInForm></SignInForm>
    </div>
    <div v-else>
      <h1>Sign out</h1>
      <LogoutForm></LogoutForm>
    </div>
  </main>
</template>
