<script setup>
import { ref, reactive, watch } from 'vue'
import gsap from 'gsap'

const props = defineProps({
  number: {
    type: Number,
    default: 0
  }
});

const internalNumber = ref(0);
const tweened = reactive({
  number: internalNumber.value
});


watch(() => props.number, (newValue) => {
  gsap.to(internalNumber, {
    duration: 2, 
    value: newValue,
    onUpdate: () => {
      tweened.number = internalNumber.value;
    },
    ease: "power1.out"
  });
}, { immediate: true });



const formatNumber = (num) => {
  // Convert the input to a number just to be sure
  const numberValue = Math.round(parseFloat(num));

  if (numberValue >= 1e6 && numberValue < 1e9) {
    return `${(numberValue / 1e6).toFixed(1)}M`;
  } else if (numberValue >= 1e9) {
    return `${(numberValue / 1e9).toFixed(1)}B`;
  } else {
    return numberValue.toLocaleString();
  }
}

</script>

<template> 
{{ formatNumber(tweened.number) }}
</template>