
<script  >
import Batch from '../components/Batch.vue'
import BaseLayout from '../components/Base.vue'
import CreditModal from '../components/OutOfCreditsModal.vue';
import ImgPlaceHolder from '../components/ImgPlaceHolder.vue';
import SegmentedControl from '../components/SegmentedControl.vue';

import { isAuthenticated } from '../libs/user';
import { isMobile } from '../libs/utils';
import apiClient from '../libs/api';

import { debounce } from 'lodash';

export default {
  components: {
    Batch,
    BaseLayout,
    CreditModal,
    ImgPlaceHolder,
    SegmentedControl
  },

  data() {
    return {
      IsMoreOptionsOpen: false,
      isMobile: isMobile(),
      footerHeight: 0,
      isAuthenticated: isAuthenticated(),
      generatedImages: [],
      prompt: '',
      negativePrompt: '',
      imageStyle: "realism", // "anime" or "realism
      aspectRatio: 3,
      showButton: false, // button scroll to top
      walletValue: null,
      isBouncing: false,
      notifyOutOfCredits: false,
      scrollableAreaNode: null,
      generatingImagesCount: 0,
      loadingText: '',
      partialImage: null,
    };
  },

  async mounted() {
    this.getScrollableAreaNode().addEventListener("scroll", this.updateShowButton);
    this.updateCreditsBalance();
    this.updateIsMobile()
    window.addEventListener('resize', this.updateIsMobile)
    window.addEventListener('resize', this.adjustBatchView);
    this.adjustBatchView();
    try {
      const response = await apiClient.getLastGeneratedImages(50);
      this.generatedImages = response.data
    } catch (error) {
      console.error(error);
      alert("Something unexpected happened. Please try again later.")
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.updateIsMobile)
    window.removeEventListener('resize', this.adjustBatchView)
    if (this.getScrollableAreaNode()) {
      this.getScrollableAreaNode().removeEventListener("scroll", this.updateShowButton);
    }
  },
  computed: {
    sortedGeneratedImages() {
      // TODO We should move that to a computed property
      // on parent
      if (this.isMobile) {
        // Create a copy of the original list before reversing
        // because reverse() mutates the original array
        // and we we would reversing the whole list again and again
        return [...this.generatedImages].reverse();
      }
      // On desktop, return the original list
      return this.generatedImages;
    },
    textualAspectRatio() {
      return ['landscape', 'square', 'portrait'][this.aspectRatio - 1]
    },
  },

  methods: {

    getScrollableAreaNode() {
      return this.$refs.mainContent?.parentNode;
    },
    // We don't want this function to be called too many times
    // since it happens on image loading but makes a request to the
    // server. So we debounce it to happen only once every second max.
    updateCreditsBalance: debounce(async function () {
      const response = await apiClient.getCreditsBalance();
      if (response.status === 200) {
        this.walletValue = response.data.filter(a => a.type === 'normal')[0].balance;
      } else {
        console.error(response);
        this.walletValue = null;
      }
    }, 1000),
    mobilePromptFormFooterHeight() {
      return (this.isMobile ? `${this.footerHeight}px` : '0px');
    },
    adjustBatchView() {
      // Make sure the sticky footer is corrected padded
      if (this.isMobile) {
        this.footerHeight = this.$refs.stickyFooter.clientHeight;
        this.$nextTick(this.scrollToBottom)
      }
    },
    scrollToBottom() {
      const container = this.getScrollableAreaNode();
      if (container) {
        container.scrollTo({
          top: 100000000,  // scroll à la fin du document
        });
      }
    },
    toggleMoreOptions() {
      this.IsMoreOptionsOpen = !this.IsMoreOptionsOpen
      this.$nextTick(this.adjustBatchView)
    },
    listHasChanged() {
      // Emit the event again so BaseLayout can detect it
      this.$emit('listChanged');
    },
    updateIsMobile() {
      this.isMobile = isMobile()
    },

    decrementGeneratingCount() {
      if (this.generatingImagesCount > 0) {
        this.generatingImagesCount--;
      }
    },

    async generateImages() {

      if (this.generatingImagesCoun) {
        return
      }

      let response;
      try {
        this.generatingImagesCount++;
        this.adjustBatchView()
        response = await apiClient.requestImageGeneration(
          this.prompt,
          this.negativePrompt,
          this.textualAspectRatio,
          this.imageStyle
        );
      } catch (error) {
        this.decrementGeneratingCount()
        if (error?.response?.status === 402) {
          this.notifyOutOfCredits = true
          return
        }
        console.error(error);
        alert('Something unexpected happened. Please try again later.')
        return
      }

      const deliveryId = response.data.id;

      // Poll the delivery status until it's finished
      const intervalId = setInterval(async () => {

        response = null;

        try {
          response = await apiClient.getDeliveryStatus(deliveryId);
        } catch (error) {
          console.error(error);
          clearInterval(intervalId)
          this.generatingImagesCount--
          alert('Something unexpected happened. Please try again later.')
          return
        }

        const { error, started_at, finished_at, progress, images_urls, generation_id } = response.data;
        if (error) {
          console.error("Error while generating image:", error);
          clearInterval(intervalId)
          this.generatingImagesCount--
          alert('An error occured while generating your images. We gave you a refund. Try later.');
          return;
        }

        if (!started_at || progress < 0) {
          this.loadingText = `In queue...`
        }

        if (started_at && progress >= 0) {
          this.loadingText = `${progress}%`
        }

        if (images_urls.length > 0) {
          this.partialImage = images_urls[0]
        }

        // Generation is done, we can load and add the last batch of images
        if (finished_at) {
          clearInterval(intervalId)
          const response = await apiClient.getGeneratedImage(generation_id)
          this.loadingText = ""
          this.partialImage = null
          this.generatedImages.unshift(response.data)
          this.$nextTick(this.adjustBatchView)
        }
      }, 1000);
    },
    backToTop() {
      const container = this.getScrollableAreaNode();
      container.scrollTo({ top: 0, behavior: "smooth" });
    },
    updateShowButton() {
      const container = this.getScrollableAreaNode();
      if (container.scrollTop > 20) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    async decrementAndAnimate() {
      if (this.walletValue && this.walletValue > 0) {
        this.walletValue--;
        this.startBouncing();
      }
    },
    startBouncing() {
      this.isBouncing = true;
    },
    reuseGeneration(image) {
      console.log(image.style)
      this.prompt = image.prompt;
      this.negativePrompt = image.negative_prompt;
      this.imageStyle = image.style;
      this.aspectRatio = {
        'landscape': 1,
        'square': 2,
        'portrait': 3,
      }
      [image.aspect_ratio];
    },
    adjustPromptHeight(event) {
      event.target.style.height = 'auto'; // Reset the height
      event.target.style.height = (event.target.scrollHeight) + 'px';
    }
  },

}

</script>

<template>
  <BaseLayout :is-user-logged-in="isAuthenticated" @listChanged="listHasChanged">
    <CreditModal :showModal="notifyOutOfCredits" @dismissed="notifyOutOfCredits = false" />

    <main ref="mainContent" :style="{ paddingBottom: mobilePromptFormFooterHeight() }">
      <div class="p-6 md:ml-64 h-auto md:mt-12   w-auto ">
        <ImgPlaceHolder v-if="!isMobile && generatingImagesCount > 0" :loading-text="loadingText"
          :partial-image="partialImage">
        </ImgPlaceHolder>
        <Batch @image-loaded="adjustBatchView() || decrementGeneratingCount() || updateCreditsBalance()"
          :generated-images="sortedGeneratedImages" @back-to-top="backToTop" :show-button="showButton"
          @reuse-generation="reuseGeneration" />
        <ImgPlaceHolder v-if="isMobile && generatingImagesCount > 0" :loading-text="loadingText"
          :partial-image="partialImage" />
      </div>
    </main>

    <div class="md:flex ">
      <div ref="stickyFooter"
        class="  bottom-0 md:top-0 fixed   left-0 z-30 w-full md:w-64 md:mt-10 h-content  bg-white border-gray-200 md:translate-x-0 dark:bg-gray-800 dark:border-gray-700  "
        aria-label="Sidenav" id="drawer-navigation" aria-hidden="true">
        <div class="overflow-y-auto md:mt-6 pb-5 px-3 h-full bg-white dark:bg-gray-800">

          <form action="#" method="GET" class="" @submit.prevent="generateImages()">
            <ul class="space-y-2">
              <!-- Menu -->
              <li class="hidden md:block ">
                <label for="credits"
                  class="block mb-2 mt-2 text-sm font-medium text-gray-900 dark:text-gray-200">Credits</label>
                <div class="inline-flex rounded-md shadow-sm" role="group">
                  <span data-tooltip-target="tooltip-credits" data-tooltip-placement="top"
                    class="flex items-center justify-center px-4 py-2 text-sm font-medium   border rounded-l-lg   focus:z-50 focus:ring-2  dark:bg-gray-800 dark:border-gray-600 dark:text-white     ">
                    <svg class="w-6 h-4 text-gray-800 dark:text-white mr-4" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        d="M11.074 4 8.442.408A.95.95 0 0 0 7.014.254L2.926 4h8.148ZM9 13v-1a4 4 0 0 1 4-4h6V6a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1v-2h-6a4 4 0 0 1-4-4Z" />
                      <path
                        d="M19 10h-6a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1Zm-4.5 3.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12.62 4h2.78L12.539.41a1.086 1.086 0 1 0-1.7 1.352L12.62 4Z" />
                    </svg> {{ walletValue === null ? "?" : walletValue }}
                  </span>
                  <div id="tooltip-credits" role="tooltip"
                    class="whitespace-nowrap absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    You have 20 credits left, they're renewed every 24hrs, upgrade for unlimited credits
                    <div class="tooltip-arrow" data-popper-arrow></div>
                  </div>

                  <span data-tooltip-target="tooltip-credit" data-tooltip-placement="top"
                    class="flex items-center justify-center   px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-mdflex rounded-r-lg   focus:z-50 focus:ring-2  dark:bg-gray-800 dark:border-gray-600 dark:text-white     ">
                    <svg class="w-6 h-3 text-gray-800 dark:text-white mr-4" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M16.5 7A2.5 2.5 0 0 1 19 4.5V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2.5a2.5 2.5 0 1 1 0 5V12a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V9.5A2.5 2.5 0 0 1 16.5 7Z" />
                    </svg> 1
                  </span>
                  <div id="tooltip-credit" role="tooltip"
                    class="whitespace-nowrap absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    In this mode, each image generation cost 1 credit
                    <div class="tooltip-arrow" data-popper-arrow></div>
                  </div>
                </div>
              </li>

              <!-- Desktop prompt -->
              <li class="hidden md:block ">
                <label for="prompt" class=" mb-2 text-sm font-medium text-gray-900 dark:text-gray-200">Prompt</label>
                <textarea @input="adjustPromptHeight" id="default-prompt" v-model="prompt"
                  @keyup.ctrl.enter="generateImages()"
                  class="block w-full p-4 overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-md	scrollbar-corner-gray-600   scrollbar-thumb-gray-800 scrollbar-track-gray-700 max-h-[300px] text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="Prompt your imagination...." required></textarea>
              </li>

              <!-- condensed prompt -->
              <li class="relative md:hidden">
                <label for="prompt"
                  class="block mb-2  text-sm font-medium text-gray-900 dark:text-gray-200">Prompt</label>
                <div class="absolute right-0 top-0 flex items-center mt-1">
                  <div :class="{ 'bouncing': isBouncing }" @animationend="isBouncing = false"
                    class="flex items-center mr-2 dark:text-white">
                    <svg class="w-6 h-3 text-gray-800 dark:text-white " aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        d="M11.074 4 8.442.408A.95.95 0 0 0 7.014.254L2.926 4h8.148ZM9 13v-1a4 4 0 0 1 4-4h6V6a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1v-2h-6a4 4 0 0 1-4-4Z" />
                      <path
                        d="M19 10h-6a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1Zm-4.5 3.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12.62 4h2.78L12.539.41a1.086 1.086 0 1 0-1.7 1.352L12.62 4Z" />
                    </svg>
                    <span class=" text-xs">{{ walletValue === null ? "?" : walletValue }}</span>
                  </div>

                  <div class="flex items-center ml-4 dark:text-white">
                    <svg class="w-6 h-2 text-gray-800 dark:text-white " aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M16.5 7A2.5 2.5 0 0 1 19 4.5V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2.5a2.5 2.5 0 1 1 0 5V12a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V9.5A2.5 2.5 0 0 1 16.5 7Z" />
                    </svg>
                    <span class=" text-xs">1</span>
                  </div>
                </div>

                <!-- Textarea -->
                <textarea id="default-prompt" v-model="prompt"
                  class="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="Prompt your imagination...." required></textarea>
              </li>



              <!-- Titre du menu accordeon avec le caret -->
              <button type="button" @click="toggleMoreOptions()"
                class="flex justify-between items-center w-full   py-2 text-gray-900 dark:text-gray-200 hover:bg-gray-200   dark:bg-gray-600 px-2 rounded-md transition duration-150 ease-in-out">
                More Options
                <svg class="h-4 w-4 transform transition-transform duration-150"
                  :class="{ 'rotate-180': IsMoreOptionsOpen }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                  fill="currentColor">
                  <path
                    d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                </svg>
              </button>

              <!-- Contenu caché du menu accordeon -->
              <div v-show="IsMoreOptionsOpen" class=" transition duration-150 ease-in-out">



                <li>
                  <label for="exclude"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-200">Exclude</label>
                  <textarea id="exclude" rows="1" v-model="negativePrompt"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-red-500 dark:focus:border-red-500"
                    placeholder="Words to exclude..."></textarea>
                </li>
                <li class="hidden">
                  <label for="genre" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-200">Genre</label>
                  <select id="genre"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-red-500 dark:focus:border-red-500">
                    <option selected>Anime</option>
                    <option value="US" class=" ">Photo</option>
                  </select>
                </li>

                <li class="md:block ">
                  <label for="style"
                    class="block mb-2 mt-2 text-sm font-medium  text-gray-900 dark:text-gray-200">Style</label>
                  <segmented-control name="style" :options="{ 'Photo': 'realism', 'Anime': 'anime' }"
                    v-model="imageStyle"></segmented-control>
                </li>

                <li>
                  <label for="aspect" class="block mb-2 mt-2 text-sm font-medium text-gray-900 dark:text-gray-200">Aspect
                    Ratio</label>
                  <input id="default-range" type="range" min="1" max="3" step="1" v-model="aspectRatio"
                    class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700">
                  <div class="flex justify-between items-center dark:bg-gray-800 dark:text-gray-200 p-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="tabler-icon tabler-icon-aspect-ratio">
                      <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
                      <path d="M7 12v-3h3"></path>
                      <path d="M17 12v3h-3"></path>
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="tabler-icon tabler-icon-aspect-ratio" style="transform: rotate(90deg);">
                      <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
                      <path d="M7 12v-3h3"></path>
                      <path d="M17 12v3h-3"></path>
                    </svg>
                  </div>
                </li>
                <li class="hidden">
                  <label for="vulva" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-200">Better
                    Vulva</label>
                  <input id="default-range" type="range" min="0" max="10" step="1" value="0"
                    class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700">
                </li>
                <li class="hidden">
                  <label for="breast" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-200">Breast
                    Size</label>
                  <input id="default-range" type="range" min="0" max="10" step="1" value="2"
                    class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700">
                </li>
                <li class="hidden">
                  <label for="ahegao"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-200">Ahegao</label>
                  <input id="default-range" type="range" min="0" max="10" step="1" value="5"
                    class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700">
                </li>
              </div>

              <li>
                <!--  prompt input -->
                <button type="submit" @click="decrementAndAnimate" :disabled="generatingImagesCount > 0"
                  class="flex items-center justify-center w-full text-white   right-2.5 bottom-2.5 bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-3 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                  Create
                  <p class="ml-2 hidden md:block text-gray-500 dark:text-gray-400">
                    <kbd
                      class="px-2 py-1.5 text-xs font-semibold text-red-800 bg-red-200 border border-red-300 rounded-lg dark:bg-red-500 dark:text-red-100 dark:border-red-600">Ctrl</kbd>
                    + <kbd
                      class="px-2 py-1.5 text-xs font-semibold text-red-800 bg-red-200 border border-red-300 rounded-lg dark:bg-red-500 dark:text-red-100 dark:border-red-600">Enter</kbd>
                  </p>
                </button>

              </li>

            </ul>
          </form>

        </div>
      </div>
    </div>
  </BaseLayout>
</template>
