import { createRouter, createWebHistory } from 'vue-router';

// Importez vos composants de vue
import Index from '../screens/Index.vue';
import Editor from '../screens/Editor.vue';
import Pricing from '../screens/Pricing.vue';
import Complete from '../screens/Complete.vue';
import Privacy from '../screens/Privacy.vue';
import Account from '../screens/Account.vue';
import Gallery from '../screens/Gallery.vue';
import Error404 from '../screens/404.vue';
import Tos from '../screens/Tos.vue';
import LogingLogout from '../screens/LogingLogout.vue';
import { isAuthenticated } from './user';

const routes = [
  {
    path: '/editor',
    name: 'editor',
    component: Editor,
    meta: { requiresAuth: true } // this route requires authentication
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: { requiresAuth: true } // this route requires authentication
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery,
    meta: { requiresAuth: true } // this route requires authentication
  },
  {
    path: '/complete',
    name: 'complete',
    component: Complete,
    meta: { requiresAuth: true } // this route requires authentication
  },
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: Pricing
  },
  {
    path: '/login',
    name: 'login',
    component: LogingLogout
  },
  {
    path: '/tos',
    name: 'tos',
    component: Tos
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: Error404,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  // Check if user is authenticated
  const authenticated = isAuthenticated();

  if (requiresAuth && !authenticated) {
    // Redirect to login
    next({ name: 'login' });
  } else {
    next(); // proceed to the route
  }
});

export default router;
