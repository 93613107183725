<script   >
import BaseLayout from '../components/Base.vue'
import { isAuthenticated } from '../libs/user';
 

export default {
  components: {
    BaseLayout
  },
  data() {
    return { 
      isUserLoggedIn: isAuthenticated(),
    };
  }
}

</script>
<template>
  <BaseLayout :is-user-logged-in="isUserLoggedIn">
    <div class="mt-16 flex flex-col items-center justify-center text-gray-300">  

      <div class="max-w-4xl mx-auto p-6">
        <h1 class="text-3xl font-bold mb-4 text-center">Terms and Conditions</h1>
        
        <p class="text-gray-500 mb-4">Thank you for choosing Kawaix.com, a product of Btcode SASU. Before using our platform, please familiarize yourself with these terms and conditions. Your acceptance ensures a harmonious experience for all parties involved.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">1. Overview of Kawaix.com Services</h2>
        <p class="text-gray-500 mb-4">Kawaix.com is a cutting-edge platform designed for AI-powered image generation. It offers users a limited free trial, with the option of a monthly subscription for extended access. We also take pride in our curated public galleries which display our users' creations.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">2. Procedures and Policies for Account Creation</h2>
        <p class="text-gray-500 mb-4">To access our full range of services, users are required to create an account. Protecting your login details, particularly passwords, is of utmost importance. Inform us immediately if you suspect any unauthorized activities on your account.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">3. Responsibility Over User Generated Content</h2>
        <p class="text-gray-500 mb-4">Users are encouraged to create and share content. However, you are solely responsible for ensuring the content aligns with legal standards and does not infringe on others' rights.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">4. Our Rights to Moderate and Manage User Content</h2>
        <p class="text-gray-500 mb-4">Kawaix.com is dedicated to providing a safe platform. We retain the right to assess, moderate, and, if necessary, remove content that we find offensive or that breaches these terms. Your cooperation is appreciated in this effort.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">5. Guidelines for Using Our Services</h2>
        <p class="text-gray-500 mb-4">Enjoy our platform, but remember to always act within the confines of the law. Activities that violate local, national, or international laws are strictly prohibited. Fraudulent behaviors will not be tolerated and may lead to permanent bans.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">6. Policy Details on Payments and Refund Procedures</h2>
        <p class="text-gray-500 mb-4">Any fees, including potential taxes, related to our services will be shouldered by the user. We offer multiple payment options for convenience. Refund requests will be evaluated on a case-by-case basis, with Kawaix.com reserving the final decision.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">7. Rights Conferred to Us on User Content Submission</h2>
        <p class="text-gray-500 mb-4">Submitting content gives us certain permissions. This may include modifying, reproducing, and publicly displaying your content across various platforms. Rest assured, we respect and protect your rights as a content creator.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">8. Declaration of Intellectual Property Ownership</h2>
        <p class="text-gray-500 mb-4">All the material present on Kawaix.com is the intellectual property of Btcode SASU or our associates. This content is protected under French and international copyright laws.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">9. Gathering of Device Data for Enhanced Security Measures</h2>
        <p class="text-gray-500 mb-4">Our platform uses device fingerprinting to enhance security. This process collects specific data from your device to create a unique identifier, preventing fraudulent activities and ensuring user safety.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">10. Restrictions on Unauthorized Access Methods</h2>
        <p class="text-gray-500 mb-4">To protect our platform's integrity, unauthorized automated access methods, including bots and scripts, are prohibited. Always seek our permission before employing such techniques.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">11. Limitations on Service Guarantees and Liabilities</h2>
        <p class="text-gray-500 mb-4">While we strive to offer an impeccable service, Kawaix.com cannot promise uninterrupted or error-free experiences. We disclaim responsibility for any potential damages or data losses incurred during platform usage.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">12. Termination of Contractual Agreement</h2>
        <p class="text-gray-500 mb-4">We reserve the right to conclude this agreement at any point. If you decide to end this contract, you can do so by discontinuing our services.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">13. Notifications on Updates and Terms Amendments</h2>
        <p class="text-gray-500 mb-4">Occasionally, we might update these terms to better serve our community. Your continued usage of Kawaix.com following these updates signifies your consent to the amended terms.</p>

        <h2 class="text-l font-semibold mt-6 mb-2">14. Communication Channels for Queries and Feedback</h2>
        <p class="text-gray-500 mb-4">Your feedback and queries are valuable to us. If you have questions or require clarifications on these terms, please contact us at <a href="mailto:contact@kawaix.com" class="text-[#fe0000] underline">contact@Kawaix.com</a>.</p>
      </div> 
    </div>
  </BaseLayout>
</template>


 