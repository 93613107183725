<script>

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      TimeRemaining: 0,
    };
  },
};
</script>


<template>
  <div v-if="showModal" class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
    <!-- Overlay -->
    <div class="absolute top-0 left-0 w-full h-full bg-black opacity-50" @click.self="$emit('dismissed')"></div>



    <!-- Modal -->
    <div class="relative bg-white dark:bg-gray-800 p-4 rounded shadow-lg w-11/12 xs:w-4/5 md:w-96 m-auto">

      <!-- Close button -->
      <button
        class="absolute top-2 right-2 w-10 h-10 p-2 text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
        @click="$emit('dismissed')">
        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <h2 class="text-xl font-bold mb-2 text-gray-800 dark:text-gray-200">
        Credits Exhausted
      </h2>
      <p class="text-gray-600 dark:text-gray-400 mb-4">
        Your credits have run out. Please purchase more credits to continue<spans v-if="TimeRemaining">, or wait {{
          TimeRemaining }} hours for additional free credits</spans>.
      </p>

      <router-link to="/pricing"
        class="relative flex items-center justify-between   mb-2   text-left text-sm font-medium text-gray-900 focus:outline-none   rounded-lg border  focus:z-10 focus:ring-4  dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
        <button type="button"
          class="w-full relative mt-2 flex items-center justify-between py-2.5 px-5 mr-2 mb-2 uppercase  text-left text-sm font-medium">
          <div>
            <p class="text-xs text-gray-200 leading-tight">upgrade membership</p>
            <p class="mt-1">view membership plans</p>
          </div>
          <svg class="w-6 h-6 text-gray-800 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 10 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 5 5 1 1 5m8 6L5 7l-4 4" />
          </svg>
        </button>
      </router-link>

    </div>
  </div>
</template>
