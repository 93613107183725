import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './libs/router'; // Assurez-vous que le chemin est correct
import * as Sentry from "@sentry/vue";

import { createDevTools } from '@vue/devtools';

const app = createApp(App);

// Vue dev tools browser extensions setup
if (import.meta.env.DEV === 'development') {
  const devtools = createDevTools(app);
  app.use(devtools);
}

Sentry.init({
  app,
  dsn: "https://7bd4e8ba8473224161dabd6aee9e2d90@o265768.ingest.sentry.io/4505730109800448",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "127.0.0.1", "https:kawaix.com"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(router);
app.mount('#app')
